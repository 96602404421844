<template>
  <div class="app-modal__box m-5">
    <div class="app-modal__header">
      <h3 class="app-title">{{ $t('Translations') }}</h3>
      <p class="text-gray-500">
        {{
          $t(
            'Add a translation for each language which is enabled in the settings. If there is no translation, the system will show the fallback language.'
          )
        }}
      </p>
    </div>
    <div class="app-modal__body">
      <BaseSelect
        class="mt-4"
        :name="'status'"
        :id="'status'"
        :value="selectedLanguage"
        @selectionChange="selectedLanguage = $event"
      >
        <template #default>
          <option
            v-for="(lang, index) in locales"
            :key="index"
            :value="lang.code"
          >
            {{ lang.label }}
          </option>
        </template>
      </BaseSelect>
      <Collapse class="my-4">
        <template #header>{{ $t('Add key value pair') }}</template>
        <template #content>
          <div class="flex flex-col space-y-4">
            <BaseInput
              class="mt-4"
              :value="newKey"
              @input="newKey = $event.target.value"
              :placeholder="$t('Key')"
            ></BaseInput>
            <BaseInput
              class="mt-4"
              :value="newValue"
              @input="newValue = $event.target.value"
              :placeholder="$t('Value')"
            ></BaseInput>
            <BaseButton
              class="mt-4"
              type="primary"
              :label="'Add Translation'"
              @click="addTranslation()"
            ></BaseButton>
          </div>
        </template>
      </Collapse>
      <BaseTable :data="tableData" :columns="columns" v-if="$features.newUI">
        <template #value-field="{ row }">
          <BaseInput
            :placeholder="row.key"
            :value="row.value"
            @input="
              ;(row.value = $event.target.value),
                updateTranslation(row.key, row.value)
            "
          ></BaseInput>
        </template>
      </BaseTable>
      <TailwindComponentsTable
        v-else
        class="mt-4"
        :itemsPerPage="10"
        :data="tableData"
        :columns="columns"
      >
        <template #value-field="{ row }">
          <BaseInput
            :placeholder="row.key"
            :value="row.value"
            @input="
              ;(row.value = $event.target.value),
                updateTranslation(row.key, row.value)
            "
          ></BaseInput>
        </template>
      </TailwindComponentsTable>
    </div>
    <div class="app-modal__footer--settings mt-4">
      <BaseButton
        type="'secondary'"
        :label="'Cancel'"
        @click="close()"
      ></BaseButton>
      <BaseButton :label="'Save'" @click="save()"></BaseButton>
    </div>
  </div>
</template>

<script>
import BaseSelect from '~/components/base/Select.vue'
import LanguageFilter from '~/components/Filter/Language.vue'
import Collapse from '~/components/TailwindComponents/Collapse.vue'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
import enJson from '~/locales/sites/en.json'
import languageData from '~/assets/language-codes.json'

export default {
  components: {
    BaseSelect,
    LanguageFilter,
    Collapse,
  },
  setup() {
    const { userProfile } = useKeycloak()
    const store = useSiteStore()

    return {
      userProfile,
      store,
    }
  },
  watch: {
    rsCollectionTemp: {
      handler(val) {
        this.store.update({
          rsCollection: JSON.parse(JSON.stringify(val)),
        })
        val = {}
      },
      deep: true,
    },
    selectedLanguage: {
      handler(value, oldValue) {
        if (oldValue) {
          let foundLang = this.locales.find(
            l => oldValue === l.code
          ).translations
          for (let i = this.tableData.length - 1; i >= 0; i--) {
            const { key, value } = this.tableData[i]
            foundLang[key] = value
          }
          const index = this.locales.findIndex(l => oldValue === l.code)
          this.locales[index].translations = foundLang
        }

        this.tableData = []
        this.translations = this.locales?.find(
          l => value === l.code
        ).translations

        for (const [key, value] of Object.entries(this.translations)) {
          if (typeof value === 'string' || value instanceof String) {
            this.tableData.push({ key: key, value: value })
          }
        }
      },
    },
  },

  props: {
    // locales: Array,
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    languageArr() {
      return this.rsCollection?.locales?.map(lang => ({
        English: lang.label,
        alpha2: lang.code,
      }))
    },
    locales() {
      return this.rsCollectionTemp?.locales
    },
    currentLocale() {
      let lang = this.$i18n.locales
        .filter(i => i.code === this.$i18n.locale)
        .pop()

      if (!languageData[0][lang?.name]) {
        lang = { code: 'en', iso: 'en', name: 'English', label: 'EN' }
      }
      return lang
    },
  },
  mounted() {
    this.rsCollectionTemp = JSON.parse(JSON.stringify(this.rsCollection))
    this.selectedLanguage = this.locales[0].code
  },
  data() {
    return {
      selectedLanguage: null,
      translations: null,
      tableData: [],
      tempLang: null,
      rsCollectionTemp: null,
      newKey: '',
      newValue: '',
      columns: [
        {
          field: 'key',
          label: this.$t('Key'),
          visible: true,
          display: true,
          searchable: true,
        },
        {
          field: 'value',
          label: this.$t('Value'),
          visible: true,
          display: true,
          custom: true,
          searchable: true,
          slotName: 'value-field',
        },
      ],
    }
  },
  methods: {
    updateTranslation(key, value) {
      const foundLang = this.locales?.find(
        l => this.selectedLanguage === l.code
      ).translations
      foundLang[key] = value
    },
    close() {
      this.$bus.emit('close-modal')
    },
    addTranslation() {
      const key = this.newKey
      const value = this.newValue

      this.translations[key] = value

      this.newKey = ''
      this.newValue = ''
    },
    setLanguages(value) {
      value?.forEach(lang => {
        if (
          !this.rsCollectionTemp.locales.map(l => l.code).includes(lang.alpha2)
        ) {
          this.rsCollectionTemp?.locales.push({
            label: lang[this.currentLocale.name],
            code: lang.alpha2,
            translations: enJson,
          })
        }
      })
      let filtedLanguage = this.rsCollectionTemp?.locales?.filter(lang =>
        value.map(l => l.alpha2).includes(lang.code)
      )
      if (this.rsCollectionTemp?.locales) {
        this.rsCollectionTemp.locales = filtedLanguage
      }
    },
    save() {
      console.log('save')

      this.$bus.emit('saveLocales', this.locales)

      this.close()
    },
  },
}
</script>
