<template>
  <div class="app-slide-over__wrapper">
    <div class="app-slide-over__header">
      <h5 class="app-title">{{ $t('Site Members') }}</h5>
    </div>
    <div class="app-slide-over__body">
      <BaseTableBlock class="mb-3" v-if="$features.invitations">
        <template v-slot:row-1>
          <p class="text-primary mr-4">
            {{
              $t(
                'Invite others to your site to collaborate. An invitation is specific to an email address.'
              )
            }}
          </p>
          <BaseButton
            small
            :symbol="'person_add'"
            :label="'Invite members'"
            @click="openInviteModal()"
          ></BaseButton>
        </template>
      </BaseTableBlock>
      <BaseTableBlock class="mb-3" v-else>
        <template v-slot:row-1>
          <h5 class="text-primary">{{ userList?.length }} Users</h5>
          <BaseButton
            small
            :icon="'plus'"
            :label="'Add User'"
            :type="addUser ? 'secondary' : 'primary'"
            @click="addUser = !addUser"
          ></BaseButton>
        </template>
      </BaseTableBlock>

      <div v-if="addUser && !$features.invitations">
        <div class="flex gap-x-2">
          <BaseInput
            :placeholder="'Search by Email...'"
            :leading="'magnify'"
            :error="invalidEmail"
            :errorText="inputError"
            :helpText="helpText"
            :value="email"
            @input="email = $event.target.value"
            v-on:keyup.native.enter="searchUserMail"
          ></BaseInput>
          <BaseButton
            small
            :disabled="!email"
            :loading="userLoading"
            :label="'Search'"
            :icon="'account-search'"
            @click="searchUserMail"
          ></BaseButton>
        </div>

        <section
          v-if="foundUsers && foundUsers.length > 0 && !$features.invitations"
        >
          <div class="mt-4" v-for="(user, index) in foundUsers" :key="index">
            <div
              class="flex items-center border border-gray-300 rounded-corners my-5 justify-between"
            >
              <div class="flex flex-col column p-5">
                <div class="flex text-primary font-bold">
                  {{ user.firstname }} {{ user.lastname }}
                </div>
                <p class="m-0">{{ user.email }}</p>
              </div>

              <div class="flex justify-end items-center">
                <div>
                  <BaseButton
                    :label="'Add'"
                    :type="'text'"
                    @click="addToSite(user)"
                    class="mr-2"
                  ></BaseButton>
                </div>
                <div class="p-5">
                  <BaseButton
                    icon-small
                    :icon="'close'"
                    @click="foundUsers = null"
                  ></BaseButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <BaseTabs
        v-if="$features.invitations"
        :fullwidth="true"
        :initTabIndex="tabIndex"
        :tabs="tabs"
      >
        <template #tab1>
          <BaseTable
            :data="userList"
            :columns="columns"
            v-if="$features.newUI && userList"
          >
            <template #role-field="{ row, column }">
              <span>{{
                row['role'] === ROLE_SITE_CONTRIBUTOR
                  ? 'Contributor'
                  : row['role']
              }}</span>
            </template>
            <template #action-field="{ row, column }">
              <div
                v-if="
                  canSetToOwner(row) ||
                  canLeaveCollection(row) ||
                  canRemoveFromCollection(row)
                "
                class="app-card__dropdown app-card__dropdown--left content-center max-h-8"
              >
                <label tabindex="0" class="app-card__dropdown__btn !pl-0">
                  <i
                    class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
                  ></i>
                </label>
                <ul
                  tabindex="0"
                  class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu--large"
                >
                  <li v-if="canSetToOwner(row)">
                    <span @click="addOrModifyUser(row, ROLE_OWNER)">
                      <span>{{ $t('Make Owner') }}</span>
                    </span>
                  </li>
                  <!-- <li v-if="canSetToEditor(row)">
                    <span @click="addOrModifyUser(row, ROLE_EDITOR)">
                      <span>{{ $t('Set to Contributor') }}</span>
                    </span>
                  </li> -->
                  <hr
                    v-if="
                      permissions.mySiteRole.isOwner && row.role !== 'Owner'
                    "
                    class="w-full border-gray-50 my-1"
                  />
                  <li v-if="canLeaveCollection(row)">
                    <span @click="removeFromSites(row)">
                      <span class="text-danger">{{ $t('Leave Site') }}</span>
                    </span>
                  </li>
                  <li v-if="canRemoveFromCollection(row)">
                    <span @click="removeFromSites(row)">
                      <span class="text-danger">{{
                        $t('Remove from Site')
                      }}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </template>
          </BaseTable>
          <TailwindComponentsTable
            v-if="!$features.newUI && userList"
            @remove="data => removeFromSites(data.data)"
            :data="userList"
            :columns="columns"
          >
            <template #role-field="{ row, column }">
              <span>{{
                row['role'] === ROLE_SITE_CONTRIBUTOR
                  ? 'Contributor'
                  : row['role']
              }}</span>
            </template>
            <template #action-field="{ row, column }">
              <div
                v-if="
                  canSetToOwner(row) ||
                  canLeaveCollection(row) ||
                  canRemoveFromCollection(row)
                "
                class="app-card__dropdown app-card__dropdown--left content-center max-h-8"
              >
                <label tabindex="0" class="app-card__dropdown__btn !pl-0">
                  <i
                    class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
                  ></i>
                </label>
                <ul
                  tabindex="0"
                  class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu--large"
                >
                  <li v-if="canSetToOwner(row)">
                    <span @click="addOrModifyUser(row, ROLE_OWNER)">
                      <span>{{ $t('Make Owner') }}</span>
                    </span>
                  </li>
                  <!-- <li v-if="canSetToEditor(row)">
                    <span @click="addOrModifyUser(row, ROLE_EDITOR)">
                      <span>{{ $t('Set to Contributor') }}</span>
                    </span>
                  </li> -->
                  <hr
                    v-if="
                      permissions.mySiteRole.isOwner && row.role !== 'Owner'
                    "
                    class="w-full border-gray-50 my-1"
                  />
                  <li v-if="canLeaveCollection(row)">
                    <span @click="removeFromSites(row)">
                      <span class="text-danger">{{ $t('Leave Site') }}</span>
                    </span>
                  </li>
                  <li v-if="canRemoveFromCollection(row)">
                    <span @click="removeFromSites(row)">
                      <span class="text-danger">{{
                        $t('Remove from Site')
                      }}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </template>
          </TailwindComponentsTable>
          <BasePagination
            v-if="totalUsers > itemsPerPageUsers"
            :total="totalUsers"
            :current.sync="currentPageUsers"
            :per-page="itemsPerPageUsers"
            @changePage="currentPageUsers = $event"
            :key="itemsPerPageUsers"
          ></BasePagination>
        </template>
        <template #tab2>
          <BaseTable
            :data="openInvitations"
            :columns="columnsinvitations"
            v-if="$features.newUI && !isLoading && openInvitations?.length > 0"
          >
            <template #state-field="{ row, column }">
              <span
                ><BaseBadge
                  class="!m-0"
                  rounded
                  :label="row['state']"
                  :type="row['state'] === 'PENDING' ? 'notification' : 'danger'"
                ></BaseBadge
              ></span> </template
            >]
            <template #role-field="{ row, column }">
              <span>{{
                row['role'] === ROLE_VIEWER
                  ? 'Viewer'
                  : row['role'] === ROLE_MANUAL_TRANSCRIBER
                  ? 'Manual Transcriber'
                  : row['role']
              }}</span>
            </template>
            <template #action-field="{ row, column }">
              <div
                class="app-card__dropdown app-card__dropdown--left content-center max-h-8"
              >
                <label tabindex="0" class="app-card__dropdown__btn !pl-0">
                  <i
                    class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
                  ></i>
                </label>
                <ul
                  tabindex="0"
                  class="app-card__dropdown__menu dropdown-content"
                >
                  <li>
                    <span @click="revokeInvitation(row)">
                      <span class="text-danger">{{
                        $t('Revoke Invitation')
                      }}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </template>
          </BaseTable>
          <TailwindComponentsTable
            v-if="!$features.newUI && !isLoading && openInvitations?.length > 0"
            :data="openInvitations"
            :columns="columnsinvitations"
          >
            <template #state-field="{ row, column }">
              <span
                ><BaseBadge
                  class="!m-0"
                  rounded
                  :label="row['state']"
                  :type="row['state'] === 'PENDING' ? 'notification' : 'danger'"
                ></BaseBadge
              ></span> </template
            >]
            <template #role-field="{ row, column }">
              <span>{{
                row['role'] === ROLE_VIEWER
                  ? 'Viewer'
                  : row['role'] === ROLE_MANUAL_TRANSCRIBER
                  ? 'Manual Transcriber'
                  : row['role']
              }}</span>
            </template>
            <template #action-field="{ row, column }">
              <div
                class="app-card__dropdown app-card__dropdown--left content-center max-h-8"
              >
                <label tabindex="0" class="app-card__dropdown__btn !pl-0">
                  <i
                    class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
                  ></i>
                </label>
                <ul
                  tabindex="0"
                  class="app-card__dropdown__menu dropdown-content"
                >
                  <li>
                    <span @click="revokeInvitation(row)">
                      <span class="text-danger">{{
                        $t('Revoke Invitation')
                      }}</span>
                    </span>
                  </li>
                </ul>
              </div>
            </template>
          </TailwindComponentsTable>
          <div class="content-center" v-if="isLoading">
            <TailwindComponentsLoading medium></TailwindComponentsLoading>
          </div>
          <div
            v-if="!isLoading && openInvitations.length === 0"
            class="bg-gray-50 px-10 py-16 rounded"
          >
            <p class="text-center">
              {{ $t('There are no pending invitations') }}
            </p>
          </div>
          <BasePagination
            v-if="totalInvitations > itemsPerPageInvitation"
            :total="totalInvitations"
            :current.sync="currentPageInvitation"
            :per-page="itemsPerPageInvitation"
            @changePage="currentPageInvitation = $event"
            :key="itemsPerPageInvitation"
          ></BasePagination>
        </template>
      </BaseTabs>

      <TailwindComponentsTable
        v-if="userList && !$features.invitations"
        :data="userList"
        :columns="columnsOld"
      >
        <template #role-field="{ row, column }">
          <select
            id="role"
            name="role"
            placeholder="Role"
            class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            v-model="row[column.field]"
            @change="handleRoleChange(row)"
          >
            <option value="Editor" :disabled="isOnlyOwner(row)">
              {{ $t('Editor') }}
            </option>

            <option value="Owner">
              {{ $t('Owner') }}
            </option>
          </select>
        </template>
        <template #remove-field="{ row, column, index }">
          <BaseButton
            type="text"
            :label="'Remove'"
            @click="removeFromSites(row)"
          ></BaseButton
        ></template>
      </TailwindComponentsTable>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

export default {
  setup() {
    const userApi = useUser()
    const siteStore = useSiteStore()
    const sitesApi = useSites()
    const invitationsApi = useInvitations()
    const permissions = usePermissions()
    const { userProfile } = useKeycloak()
    return {
      userApi,
      siteStore,
      sitesApi,
      permissions,
      userProfile,
      invitationsApi,
    }
  },
  props: {
    siteId: {
      type: String,
    },
  },
  data() {
    return {
      currentPageInvitation: 1,
      itemsPerPageInvitation: 12,
      totalInvitations: null,
      openInvitations: null,
      addUser: false,
      isReady: false,
      isLoading: false,
      isError: false,
      tabIndex: 0,
      activeStep: 0,
      selectedUser: null,
      removePackage: null,
      userLable: 'Select User',
      firstname: '',
      lastname: '',
      email: '',
      role: '',
      inputError: '',
      helpText: '',
      total: null,
      userList: [],
      foundUsers: null,
      itemsPerPage: 5,
      columnsinvitations: [
        {
          field: 'inviteeEmail',
          label: this.$t('Email'),
          display: true,
          searchable: true,
        },
        {
          field: 'state',
          label: this.$t('State'),
          display: true,
          custom: true,
          slotName: 'state-field',
        },
        {
          field: 'role',
          label: this.$t('Role'),
          display: true,
          custom: true,
          slotName: 'role-field',
        },
        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
      columnsOld: [
        {
          field: 'userName',
          label: this.$t('User name'),
          searchable: true,
          display: true,
          dropdown: false,
          header1: 'firstname',
          header2: 'lastname',
        },
        {
          field: 'role',
          label: this.$t('Role'),
          searchable: false,
          display: true,
          options: ['Editor', 'Owner'],
          custom: true,
          slotName: 'role-field',
        },
        {
          field: 'remove',
          label: '',
          searchable: false,
          display: true,
          dropdown: false,
          action: 'Remove',
          custom: true,
          slotName: 'remove-field',
        },
      ],
      columns: [
        {
          field: 'userName',
          label: this.$t('User name'),
          searchable: true,
          display: true,
          dropdown: false,
          header1: 'firstname',
          header2: 'lastname',
        },
        {
          field: 'role',
          label: this.$t('Role'),
          searchable: false,
          display: true,
          options: ['Editor', 'Owner'],
          custom: true,
          slotName: 'role-field',
        },
        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
      userLoading: false,
      invalidEmail: false,
      rsCollectionTemp: null,
    }
  },
  computed: {
    ...mapState(useSiteStore, ['rsCollection', 'language', 'unsavedChanges']),
    isAdmin() {
      return this.userProfile?.IsAdmin
    },
    tabs() {
      let tabs = [
        {
          title: this.$t('Site Members') + ' (' + this.userList?.length + ')',
          id: 'tab1',
        },
        { title: this.$t('Invitations'), id: 'tab2' },
      ]
      return tabs
    },
  },
  watch: {
    currentPageInvitation() {
      this.getOpenInvitations()
    },
    rsCollectionTemp: {
      handler(val) {
        this.siteStore.update({
          rsCollection: JSON.parse(JSON.stringify(val)),
        })
      },
      deep: true,
    },
  },
  created() {
    this.$bus.on('reload-members', this.reloadMembers)
  },
  destroyed() {
    this.$bus.off('reload-members', this.reloadMembers)
  },
  async mounted() {
    this.rsCollectionTemp = JSON.parse(JSON.stringify(this.rsCollection))

    await this.fetchUserList()
    if (this.$features.invitations) {
      await this.getOpenInvitations()
    }
    this.isReady = true
  },

  methods: {
    async revokeInvitation(member) {
      await this.invitationsApi.deleteSitesInvitation({
        sitesId: this.siteId,
        inviteId: member.inviteId,
      })
      await this.getOpenInvitations()
    },
    async reloadMembers(index = 1) {
      this.isLoading = true
      this.tabIndex = index
      this.permissions.setSitePermissions(this.rsCollectionTemp)
      if (this.$features.invitations) {
        await this.getOpenInvitations()
      }
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    async getOpenInvitations() {
      this.isLoading = true
      if (this.$features.invitations) {
        try {
          let response = await this.invitationsApi.fetchSitesInvitations({
            sitesId: this.siteId,
            size: this.itemsPerPageInvitation,
            page: this.currentPageInvitation - 1,
          })
          this.totalInvitations = response?.total
          this.openInvitations = response?.list?.filter(
            i => i.state !== 'ACCEPTED'
          )
        } catch (err) {
          this.$sentry.captureException(err)
        }
      }
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    canSetToOwner(user) {
      return (
        (this.permissions.sites[this.siteId]?.manageUsers || this.isAdmin) &&
        user['role'] !== ROLE_SITE_OWNER
      )
    },
    canSetToEditor(user) {
      return (
        (this.permissions.sites[this.siteId]?.manageUsers || this.isAdmin) &&
        user['role'] !== ROLE_SITE_CONTRIBUTOR
      )
    },
    // Owners can not leave the collection, they need to asign another owner first
    canLeaveCollection(user) {
      return (
        user.userId === this.userProfile.UserId &&
        !this.permissions.mySiteRole.isOwner
      )
    },
    canRemoveFromCollection(user) {
      return (
        this.permissions.sites[this.siteId]?.manageUsers &&
        user.userId !== this.userProfile.UserId &&
        !(user['role'] === ROLE_SITE_OWNER)
      )
    },
    addOrModifyUser(user, role) {
      if (role) {
        user.role = role
      }
      if (user.role === 'Owner') {
        this.userList.forEach(u => {
          if (u !== user) {
            u.role = 'Editor'
          }
        })
        let userListTemp = [...this.userList]
        userListTemp = userListTemp.filter(u => u.role !== 'Owner')
        this.rsCollectionTemp.userId = user.userId
        this.rsCollectionTemp.additionalUsers = [...userListTemp]
      }

      this.updateSites()
    },
    openInviteModal() {
      this.$emit('openSiteInviteModal')
      this.$bus.emit('slideOverClose')
    },
    async fetchUserList() {
      this.userList = [...this.rsCollectionTemp.additionalUsers]
      try {
        const owner = await this.userApi.fetchUsersById({
          userId: this.rsCollection.userId,
        })
        owner.role = 'Owner'

        this.userList.push(owner)
      } catch (err) {
        this.$sentry.captureException(err)
      }
    },
    async searchUserName() {
      if (this.firstname && this.lastname) {
        try {
          this.foundUsers = await this.userApi.findUser({
            firstName: this.firstname,
            lastName: this.lastname,
          })
        } catch (err) {
          this.$bus.emit('notification', {
            message: this.$t('User search failed'),
            type: 'error',
          })
          this.$sentry.captureException(err)
        }
      } else {
        this.$bus.emit('notification', {
          message: this.$t('Please enter first and last name'),
          type: 'error',
        })
      }
    },
    async searchUserMail() {
      this.userLoading = true
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (emailRegex.test(this.email)) {
        try {
          this.foundUsers = await this.userApi.findUser({
            mail: this.email,
          })

          this.helpText = this.foundUsers.length ? '' : 'No users found'

          this.userLoading = false
        } catch (err) {
          this.$bus.emit('notification', {
            message: this.$t('User search failed'),
            type: 'error',
          })
          this.$sentry.captureException(err)
          this.userLoading = false
        }
        this.invalidEmail = false
      } else {
        this.inputError = 'Invalid email address'
        this.helpText = ''
        this.invalidEmail = true
        this.userLoading = false
      }
    },
    async addToSite(user) {
      user.role = 'Editor'
      if (!this.userList.some(u => u.userId === user.userId)) {
        this.userList.push(user)
        let userListTemp = [...this.userList]
        userListTemp = userListTemp.filter(u => u.role !== 'Owner')

        this.rsCollectionTemp.additionalUsers = [...userListTemp]
        await this.updateSites()
      } else {
        this.$bus.emit('notification', {
          message: this.$t('User already exists'),
          type: 'error',
        })
      }
    },
    async removeFromSites(user) {
      console.log('🚀 ~ removeFromSites ~ user:', user)
      if (user.role === 'Editor') {
        const index = this.userList.findIndex(u => u === user)

        if (index !== -1) {
          this.userList.splice(index, 1)
        }

        let userListTemp = [...this.userList]
        userListTemp = userListTemp.filter(u => u.role !== 'Owner')

        this.rsCollectionTemp.additionalUsers = [...userListTemp]
        await this.updateSites()
      } else {
        this.$bus.emit('notification', {
          message: this.$t('Owner cannot be removed'),
          type: 'error',
        })
      }
    },
    async updateSites() {
      await this.sitesApi.updateRsLiteCollection({
        sites: this.rsCollectionTemp,
        customUrl: this.rsCollectionTemp.url,
      })
      this.$emit('userChange', this.rsCollectionTemp.additionalUsers)
      await this.reloadMembers(0)
      this.$bus.emit('notification', {
        message: this.$t('Updated Sites'),
        type: 'success',
        position: 'center',
      })
    },
    selected(item) {
      this.selectedUser = item
      this.activeStep = 1
      this.userLable = item.userName
    },
    async handleRoleChange(user) {
      if (user.role === 'Owner') {
        this.userList.forEach(u => {
          if (u !== user) {
            u.role = 'Editor'
          }
        })
        let userListTemp = [...this.userList]
        userListTemp = userListTemp.filter(u => u.role !== 'Owner')
        this.rsCollectionTemp.userId = user.userId
        this.$emit('ownerChange', user.userId)
        this.rsCollectionTemp.additionalUsers = [...userListTemp]
      }

      await this.updateSites()
    },

    isOnlyOwner(user) {
      return (
        this.userList.filter(u => u.role === 'Owner').length === 1 &&
        user.role === 'Owner'
      )
    },
  },
}
</script>
