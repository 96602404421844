<template>
  <nav
    class="app-navbar bg-primary app-animation"
    :class="{ '!z-[10000] -translate-y-full': !editView }"
  >
    <div class="app-navbar__wrapper">
      <BaseTableBlock>
        <template v-slot:row-1>
          <div class="flex items-center">
            <BaseButton
              as-label
              :type="'secondary'"
              :icon="'chevron-left'"
              :label="'Back'"
              @click="goToSites()"
            ></BaseButton>
            <BaseButton
              as-label
              class="ml-2"
              :type="'secondary'"
              :icon="'content-save'"
              :label="'Save'"
              @click="saveChanges()"
            ></BaseButton>
            <div
              class="absolute left-1/2 -translate-x-full -bottom-10"
              v-if="!editView"
            >
              <BaseButton
                :type="'secondary'"
                :icon="'cog'"
                @click="changeView"
              ></BaseButton>
            </div>
            <BaseButton
              class="ml-2"
              as-label
              :type="'secondary'"
              :icon="'eye'"
              :label="'Preview'"
              @click="changeView"
              v-else
            ></BaseButton>
            <span v-if="unsavedChanges" class="text-white text-sm ml-2">
              {{ $t('You have unsaved changes') }}
            </span>
          </div>
          <div>
            <span class="text-white text-lg">{{ rsCollection?.title }}</span>
          </div>
          <div class="flex items-center gap-2">
            <BaseButton
              v-if="$features.invitations"
              as-label
              :class="rsCollection?.config.theme"
              class="!px-1 !text-white !bg-sites"
              :type="'secondary'"
              :symbol="'group'"
              :for="'Members'"
              @click="emitMembersSlideOver"
            ></BaseButton>
            <BaseButton
              v-if="permissions.sites[rsCollection?.id]?.viewSettings"
              as-label
              :class="rsCollection?.config.theme"
              class="!px-1 !text-white !bg-sites"
              :type="'secondary'"
              :icon="'cog'"
              :for="'Settings'"
              @click="emitSettingsSlideOver"
            ></BaseButton>
            <BaseButton
              class="!px-1 !text-white !bg-sites"
              :class="rsCollection?.config.theme"
              :type="'secondary'"
              :icon="'bug'"
              id="sentry-bug"
            ></BaseButton>
            <BaseButton
              as-label
              class="ml-2"
              :type="'secondary'"
              :icon="'earth'"
              :label="!isPublished ? $t('Want to publish?') : $t('Manage')"
              @click="openModal"
            ></BaseButton>
          </div>
        </template>
      </BaseTableBlock>
    </div>
  </nav>
</template>
<style scoped>
.nav-text {
  margin-left: 8px;
}
</style>

<script>
import Settings from '~/components/Sites/Settings.vue'
import PublishSite from '~/components/Sites/PublishSiteModal.vue'
import PremiumModal from '~/components/Modals/Premium.vue'
import UserManager from '~/components/Sites/UserManager.vue'
import InviteToSite from '~/components/Sites/InviteToSite.vue'

import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'

export default {
  components: {
    Settings,
    PublishSite,
    PremiumModal,
  },
  setup() {
    const { userProfile } = useKeycloak()
    const store = useSiteStore()
    const sitesApi = useSites()
    const permissions = usePermissions()
    const modalConifg = useModals()
    return {
      userProfile,
      store,
      sitesApi,
      permissions,
      modalConifg,
    }
  },
  data() {
    return {
      selectedLang: '',
      drawerOpen: false,
      editView: true,
    }
  },
  props: {
    spaced: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection', 'unsavedChanges']),

    isPublished() {
      return this.rsCollection?.status === 'Published'
    },

    collId() {
      return this.$route.params.collectionid
    },
    userId() {
      if (this.userProfile?.Email) {
        this.$sentry.setUser({
          email: this.userProfile?.Email,
        })
      }
      return this.userProfile?.UserId
    },
    isAdmin() {
      return this.userProfile?.IsAdmin
    },
  },
  mounted() {
    this.selectedLang = this.language
    try {
      const feedback = this.$sentry.feedbackIntegration({
        autoInject: false,
        colorScheme: 'light',
        showBranding: false,
        showName: false,
        showEmail: false,
        enableScreenshot: true,
        themeLight: {
          submitBackground: '#193060',
          submitBackgroundHover: '#101e3d',
          inputOutlineFocus: '2px solid #193060',
          boxShadow: '0 0 1px rgba(0, 0, 0, 0.1)',
        },
      })

      feedback.attachTo(document.querySelector('#sentry-bug'), {
        formTitle: this.$t('We’re here to improve!!'),
        submitButtonLabel: this.$t('Send Report'),
        messageLabel: this.$t('Describe the issue'),
        messagePlaceholder: this.$t(
          'Tell us what went wrong and what you expected to happen.'
        ),
        cancelButtonLabel: this.$t('Cancel'),
        successMessageText: this.$t(
          'Please note: This report helps us fix bugs. We won’t reply directly to this submission.'
        ),
      })
    } catch (err) {
      console.error(err)
    }
  },
  methods: {
    setLanguage(value) {
      if (value) {
        this.store.setLanguage(value)
      }
    },
    openModal() {
      if (this.userProfile.Individual) {
        this.openPremium()
      } else {
        this.openPublishModal()
      }
    },
    openPublishModal() {
      this.modal = {
        modalContent: {
          component: PublishSite,
        },
      }
      let props = {
        isFullscreen: false,
        toggleRef: 'settings',
        customHeader: '--revert',
        maxFit: true,
        noPadding: true,
      }
      this.$bus.emit('open-modal', { modal: this.modal, props: props })
    },
    emitSettingsSlideOver() {
      const slideOver = {
        slideOver: 'Settings',
        remount: true,
        slideContent: {
          component: Settings,
          props: {},
          data: {},
        },
      }

      this.$bus.emit('slideOver', slideOver)
    },
    emitMembersSlideOver() {
      const slideOver = {
        slideOver: 'Members',
        remount: true,
        slideContent: {
          component: UserManager,
          data: {},
          props: {
            siteId: this.rsCollection.id,
          },
          events: {
            openSiteInviteModal: () => {
              this.openInviteToSite()
            },
          },
        },
      }
      this.$bus.emit('slideOver', slideOver)
    },
    openInviteToSite() {
      const modal = {
        modalContent: {
          props: {
            siteId: this.rsCollection.id,
          },
          component: InviteToSite,
        },
      }
      this.$bus.emit('open-modal', { modal: modal, props: { isSmall: true } })
    },
    changeView() {
      this.editView = !this.editView
      this.$emit('change-view')
    },
    async openPremium() {
      const modals = await this.modalConifg.getModals()
      const modal = modals.find(modal => modal.trigger === 'publish-site')
      modal.open()
    },
    goToSites() {
      const styleTemp = document.querySelector('#sites-styles')

      if (styleTemp) {
        styleTemp.remove()
      }
      this.$router.push(
        this.localePath({ name: 'sites', query: { view: 'private' } })
      )
    },
    async saveChanges() {
      try {
        let data = await this.sitesApi.updateRsLiteCollection({
          sites: this.rsCollection,
          customUrl: this.rsCollection.url,
        })
        const notification = {
          message: this.$t('Updated Sites'),
          type: 'success',
        }
        this.$bus.emit('notification', notification)
        // this.store.setSites(this.rsCollectionTemp)
      } catch (err) {
        const notification = {
          message: this.$t('Updating Sites failed'),
          type: 'danger',
        }
        this.$bus.emit('notification', notification)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.navbar.is-spaced {
  padding: 0.25rem 0.25rem !important;
}
span.select > select {
  color: white;
  background-color: #193060;
}

.select select option {
  color: white;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: white;
  color: white;
}

.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: white;
}

.select {
  select {
    border-color: transparent;

    &:hover {
      border-color: white;
    }
  }
}
</style>
